export enum TripGeneralQuestionInputType {
    Rating = 0,
    TextField = 1,
    CheckBox = 2
}

export type TripGeneralQuestion = {
    id: string;  
    name: string;
    description: string;
    isDescriptionPublic: boolean;
    inputType: TripGeneralQuestionInputType;
    GeneralQuestionTypeId: string;
    shipId: string;
    displayOrder: number;
    isActive: boolean;
    createdAt: Date;
    creatorId: string;
}

export enum GeneralQuestionType {
    Activity = 0,
}

export type TripGeneralQuestionType = {
    id: string;
    name: string;
    shipId: string;
    questionType: GeneralQuestionType;
    displayOrder: number;
    isActive: boolean;
    createdAt: Date;
    creatorId: string;
    questions: TripGeneralQuestion[];
}

export type CreateTripGeneralQuestionDto = {
    name: string;
    description: string;
    isDescriptionPublic: boolean;
    inputType: TripGeneralQuestionInputType;
    GeneralQuestionTypeId: string;
    shipId: string;
    displayOrder: number;
    isActive: boolean;
    createdAt: Date;
}

export type TripPassengerGeneralQuestionDTO = {
    tripGeneralQuestionId: string;
    passengerId: string;
    response: string;
}