import { IconButton, Popover, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useState } from "react";

type InfoButtonProps = {
  description: string;
};

export default function InfoButton({ description }: InfoButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-popover' : undefined;

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        aria-describedby={id}
      >
        <InfoOutlined fontSize="small" color="info" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }} variant="body2">
          {description}
        </Typography>
      </Popover>
    </>
  );
}
