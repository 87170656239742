export enum TripPreferenceInputType {
    Rating = 0,
    TextField = 1,
    CheckBox = 2
}

export type TripPreference = {
    id: string;  // Guid in TypeScript is represented as string
    name: string;
    description: string;
    isDescriptionPublic: boolean;
    inputType: TripPreferenceInputType;
    preferenceTypeId: string;
    shipId: string;
    displayOrder: number;
    isActive: boolean;
    createdAt: Date;
    creatorId: string;
}

export enum PreferenceType {
    Food = 0,
    Drink = 1,
}

export type TripPreferenceType = {
    id: string;
    name: string;
    shipId: string;
    type: PreferenceType;
    displayOrder: number;
    isActive: boolean;
    createdAt: Date;
    creatorId: string;
    preferences: TripPreference[];
}

export type CreateTripPreferenceDto = {
    name: string;
    description: string;
    isDescriptionPublic: boolean;
    inputType: TripPreferenceInputType;
    preferenceTypeId: string;
    shipId: string;
    displayOrder: number;
    isActive: boolean;
    createdAt: Date;
}

export type TripPassengerPreferenceDTO = {
    tripPreferenceId: string;
    passengerId: string;
    response: string;
    isAllergy: boolean;
}