import { DateTime } from "luxon"

export type PassengerID = {
  email?: string,
  joinCode?: string
}

export enum PreferencesConfig {
  All = 0,
  JustPrimary = 1,
}
export type Passenger = {
    id?: string,
    email?: string,
    joinCode?: string,
    firstName?: string,
    lastName?: string,
    onBoardingStatus?: number,
    trip?: Trip,
    ship?: Ship,
    contactInfo?: PassengerContactInfo,
    travelInfo?: PassengerTravelInfo,
    isPrimary?: boolean
}

export type Trip = {
    startDate?: DateTime,
    startLocation?: string,
    endDate?: DateTime,
    endLocation?: string,
    preferencesConfig?: PreferencesConfig
}

export type Ship = {
    id?: string,
    name?: string
}

export type PassengerContactInfo = {
    id?: string,
    firstName?: string,
    lastName?: string,
    proNouns?: string,
    dialCode?: string,
    phoneNumber?: string,
    email?: string,
    dateOfBirth?: DateTime,
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    region?: string,
    postalCode?: string,
    country?: string,
    emergencyFirstName?: string,
    emergencyLastName?: string,
    emergencyDialCode?: string,
    emergencyPhone?: string,
  }

  export type PassengerTravelInfo = {
    id?: string,
    arrivalFlightTime?: DateTime,
    arrivalFlightNumber?: string,
    arrivalFlightAirport?: string,
    arrivalTransportationRequired?: boolean,
    returnFlightTime?: DateTime,
    returnFlightNumber?: string,
    returnFlightAirport?: string,
    returnTransportationRequired?: boolean,
  }

  export type PassengerMedicalInfo={
    id?: string,
    allergies?: string,
    foodAllergies?: string,
    doNotServeAlcohol?: boolean,
    medicalConditionsOrDisabilities?: string,
    useMobilityAids?: boolean,
    useHearingAidsorCochlearImplants?: boolean,
    haveSeasickness?: boolean,
    useSeasicknessMedicine?: boolean
  }

  export type PassengerAquaticInfo={
    id?: string,
    swimmingLevel?: number,
    isCertifiedDiver?: boolean,
    divingCertificationType?: number,
    certificationNumber?: string,
    lastDiveDetails?: string
  }

  export type PassengerActivityInfo={
    id?: string,
    isWildlifeSighting?: number,
    isWildlifeBird?: boolean,
    isWildlifeFish?: boolean,
    isWildlifeSeaMammals?: boolean,
    isWildlifeAmphibiansAndLizards?: boolean,
    wildlifeOther?: string,
    isWaterBeachCombing?: boolean,
    isWaterFishing?: boolean,
    isWaterSailing?: boolean,
    isWaterScubaDiving?: boolean,
    isWaterSnorkeling?: boolean,
    isWaterSunbathing?: boolean,
    isWaterSurfing?: boolean,
    isWaterSwimming?: boolean,
    isIslandBars?: boolean,
    isIslandCycling?: boolean,
    isIslandDining?: boolean,
    isIslandGolf?: boolean,
    isIslandHiking?: boolean,
    isIslandTours?: boolean,
    isIslandKaraoke?: boolean,
    isIslandMuseumsHeritage?: boolean,
    isIslandMusicDancing?: boolean,
    isIslandNightClubs?: boolean,
    isIslandShopping?: boolean,
    isIslandSportingEvents?: boolean,
    passengerEquipment?: string,
  }
  export type PassengerDrinkInformationDTO = {
    id?: string;
    isDrinkCoke?: number;
    isDrinkDietCoke?: number;
    isDrinkSprite?: number;
    isDrinkDietSprite?: number;
    isGingerAle?: number;
    isCoffee?: number;
    isIcedCoffee?: number;
    isBlackTea?: number;
    isGreenTea?: number;
    isFloralTea?: number;
    isIcedTea?: number;
    isWholeMilk?: number;
    isMilk?: number;
    isSkimMilk?: number;
    isTapWater?: number;
    isBottledWater?: number;
    isFlavoredWater?: number;
    isGatorade?: number;
    isOrangeJuice?: number;
    isAppleJuice?: number;
    isPineappleJuice?: number;
    isCranberryJuice?: number;
    isTonicWater?: number;
    isClubSoda?: number;
    otherNonAlcoholicDrinks?: string;
    isNoAlchohol?: boolean;
    isLightBeer?: number;
    isDarkBeer?: number;
    isIPA?: number;
    isCorona?: number;
    isWhiteWine?: number;
    isRedWine?: number;
    isRose?: number;
    isSweetRedWine?: number;
    isPort?: number;
    isSparklingChampagne?: number;
    isHardTeaLemonade?: number;
    isSeltzers?: number;
    isGin?: number;
    isVodka?: number;
    isFlavoredVodka?: number;
    isRum?: number;
    isFlavoredRum?: number;
    isTequila?: number;
    isWhiskey?: number;
    isScotch?: number;
    isLiqueurs?: number;
    isBitters?: number;
    isSimpleSyrup?: number;
    isTripleSec?: number;
    isGrenadine?: number;
    isSeltzer?: number;
    otherAlcoholicDrinks?: string;
    otherMixers?: string;
    drinkAndCocktailRequests?: string;
}