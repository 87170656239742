import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Stack,
  Button,
  Alert,
  List,
  ListItem,
  Grid,
  Divider
} from "@mui/material";
import { useRecoilValue, useRecoilState } from "recoil";
import { ActivityGeneralQuestionSelector, CurrentPassengerState } from "../recoil/PassengerAtom";
import { Formik } from "formik";
import { GeneralQuestionType, TripGeneralQuestionInputType, TripPassengerGeneralQuestionDTO } from "../types/TripGeneralQuestion";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FormRatingControl from "../components/FormRatingControl/FormRatingControl";
import InfoButton from "../components/InfoButton/InfoButton";
import { Star } from "@mui/icons-material";
import { createTripPassengerGeneralQuestion, getPassengerDetails } from "../services/passengerService";

type FormValues = {
  [key: string]: {
    value: string | number | boolean;
  };
};

export default function Activities() {
  const activityQuestionTypes = useRecoilValue(ActivityGeneralQuestionSelector);
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  // Filter out inactive question types and their inactive questions
  const activeActivityQuestionTypes = activityQuestionTypes
    .filter(questionType => questionType.isActive)
    .map(questionType => ({
      ...questionType,
      questions: questionType.questions.filter(question => question.isActive)
    }));

  // Initialize form values for all active questions within each active question type
  const initialValues: FormValues = {};
  activeActivityQuestionTypes.forEach(questionType => {
    questionType.questions.forEach(question => {
      initialValues[question.id] = {
        value: question.inputType === TripGeneralQuestionInputType.Rating ? 3 : 
               question.inputType === TripGeneralQuestionInputType.CheckBox ? false : ""
      };
    });
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setError(null);

      // Convert form values to TripPassengerGeneralQuestionDTO array
      const answers: TripPassengerGeneralQuestionDTO[] = Object.entries(values).map(([questionId, formValue]) => ({
        tripGeneralQuestionId: questionId,
        passengerId: currentPassenger.id ?? "",
        response: formValue.value.toString()
      }));

      // Call the service to save answers
      createTripPassengerGeneralQuestion(GeneralQuestionType.Activity, currentPassenger.id, answers)
      .then(() => getPassengerDetails(currentPassenger).then((res) => {
        setCurrentPassenger(res.data);
        navigate("/");
      }));
      
    } catch (err) {
      setError("Failed to save activity preferences. Please try again.");
      console.error("Error saving activity preferences:", err);
    }
  };

  return (
    <Container maxWidth="md">
      <OnboardingStepper activeStep={4} />
      <Paper sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Activities of Interest
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Please tell us about your activity preferences to help us plan your trip.
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {error && (
                  <Alert severity="error" sx={{ mb: 3 }}>
                    {error}
                  </Alert>
                )}

                <Alert severity="info" variant="outlined" sx={{ mb: 4 }}>
                  <Stack direction="column">
                    <Typography variant="body1">
                      Please rate the following activities. We will do our best to accommodate everyone's
                      preferences, but please understand that some activities may not always be available.
                    </Typography>
                    <List>
                      <ListItem>1<Star fontSize="small" /> - Not Interested</ListItem>
                      <ListItem>2<Star fontSize="small" /> - Somewhat Interested</ListItem>
                      <ListItem>3<Star fontSize="small" /> - Neutral</ListItem>
                      <ListItem>4<Star fontSize="small" /> - Very Interested</ListItem>
                      <ListItem>5<Star fontSize="small" /> - Must Do</ListItem>
                    </List>
                  </Stack>
                </Alert>

                {activeActivityQuestionTypes
                  .filter(questionType => questionType.questions.length > 0)
                  .map((questionType) => (
                  <Paper 
                    key={questionType.id} 
                    variant="outlined" 
                    sx={{ 
                      p: 1.5,
                      mb: 1.5,
                      backgroundColor: 'background.default'
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        mb: 1,
                        pb: 0.5,
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      {questionType.name}
                    </Typography>
                    <Stack 
                      spacing={0.75} 
                      divider={
                        <Divider 
                          sx={{ 
                            opacity: 0.5,
                            my: 0.5
                          }} 
                        />
                      }
                    >
                      {[...questionType.questions]
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .map((question) => (
                        <Box 
                          key={question.id} 
                          sx={{ 
                            p: 1,
                            backgroundColor: 'background.paper',
                            borderRadius: 1,
                            '&:hover': {
                              backgroundColor: 'action.hover'
                            }
                          }}
                        >
                          <Grid 
                            container 
                            direction={{ xs: 'column', sm: 'row' }}
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            spacing={0.5}
                          >
                            <Grid item xs={12} sm>
                              {question.inputType === TripGeneralQuestionInputType.Rating && (
                                <FormRatingControl
                                  label={question.name}
                                  name={`${question.id}.value`}
                                  value={Number(values[question.id]?.value ?? 3)}
                                  onChange={(_, value) => setFieldValue(`${question.id}.value`, value ?? 3)}
                                  description={question.isDescriptionPublic ? question.description : undefined}
                                />
                              )}
                              
                              {question.inputType === TripGeneralQuestionInputType.TextField && (
                                <Grid container alignItems="center" spacing={0.5}>
                                  <Grid item xs>
                                    <TextField
                                      fullWidth
                                      multiline
                                      rows={4}
                                      label={question.name}
                                      name={`${question.id}.value`}
                                      value={values[question.id]?.value || ""}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      size="small"
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                    />
                                  </Grid>
                                  {question.isDescriptionPublic && question.description && (
                                    <Grid item>
                                      <InfoButton description={question.description} />
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                              
                              {question.inputType === TripGeneralQuestionInputType.CheckBox && (
                                <Grid container alignItems="center" spacing={0.5}>
                                  <Grid item>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={`${question.id}.value`}
                                          checked={values[question.id]?.value as boolean}
                                          onChange={handleChange}
                                          size="small"
                                        />
                                      }
                                      label={question.name}
                                    />
                                  </Grid>
                                  {question.isDescriptionPublic && question.description && (
                                    <Grid item>
                                      <InfoButton description={question.description} />
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Stack>
                  </Paper>
                ))}

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isSubmitting}
                  >
                    Save Activities
                  </Button>
                </Box>
              </Stack>
            </form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
}
