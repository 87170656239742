import { atom, selector } from "recoil";
import { Passenger } from "../types/passenger";
import { PreferenceType, TripPreferenceType } from "../types/TripPreference";
import { GeneralQuestionType, TripGeneralQuestionType } from "../types/TripGeneralQuestion";

export const CurrentPassengerState = atom<Passenger>({
  key: "currentPassenger",
  default: {
    id: "",
    email: "",
    joinCode: "",
    onBoardingStatus: -1,
  },
});

export const PreferenceTypeListState = atom<TripPreferenceType[]>({
  key: "preferenceTypeList",
  default: [],
});

export const GeneralQuestionTypeListState = atom<TripGeneralQuestionType[]>({
  key: "generalQuestionTypeList",
  default: [],
});

export const ActivityGeneralQuestionSelector = selector<TripGeneralQuestionType[]>({
  key: "activityGeneralQuestion",
  get: ({ get }) => {
    const questions: TripGeneralQuestionType[] = get(GeneralQuestionTypeListState);
    return questions
      .filter(pref => pref.questionType === GeneralQuestionType.Activity)
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }
});

export const FoodPreferencesSelector = selector<TripPreferenceType[]>({
  key: "foodPreferences",
  get: ({ get }) => {
    const preferences: TripPreferenceType[] = get(PreferenceTypeListState);
    return preferences
      .filter(pref => pref.type === PreferenceType.Food)
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }
});

export const DrinkPreferencesSelector = selector<TripPreferenceType[]>({
  key: "drinkPreferences",
  get: ({ get }) => {
    const preferences: TripPreferenceType[] = get(PreferenceTypeListState);
    return preferences
      .filter(pref => pref.type === PreferenceType.Drink)
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }
});
