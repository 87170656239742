import axios from "axios";
import { PassengerFoodInformation } from "../types/food";
import {
  Passenger,
  PassengerActivityInfo,
  PassengerAquaticInfo,
  PassengerContactInfo,
  PassengerDrinkInformationDTO,
  PassengerID,
  PassengerMedicalInfo,
  PassengerTravelInfo,
} from "../types/passenger";
import { saveAs } from "file-saver";
import { TripPassengerPreferenceDTO } from "../types/TripPreference";
import { TripPassengerGeneralQuestionDTO } from "../types/TripGeneralQuestion";


//const API_URL: string = "https://localhost:7046/";
//const API_URL: string = "https://localhost:32768/";
const API_URL: string = "https://api.zsail.io/";

export const getPassengerDetails = (passenger: Passenger) => {
  var id: PassengerID = {
    email: passenger.email,
    joinCode: passenger.joinCode,
  };
  return axios.post<Passenger>(`${API_URL}passengers`, id).then((response) => {
    return response;
  });
};

export const createContactInfo = (
  passengerId?: string,
  contactInfo?: PassengerContactInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/contactinfo`, contactInfo)
    .then((res) => {
      return res.data;
    });
};

export const createTravelInfo = (
  passengerId?: string,
  travelInfo?: PassengerTravelInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/travelinfo`, travelInfo)
    .then((res) => {
      return res.data;
    });
};

export const createMedicalInfo = (
  passengerId?: string,
  medicalInfo?: PassengerMedicalInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/medicalinfo`, medicalInfo)
    .then((res) => {
      return res.data;
    });
};
export const createAquaticInfo = (
  passengerId?: string,
  aquaticInfo?: PassengerAquaticInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/aquaticinfo`, aquaticInfo)
    .then((res) => {
      return res.data;
    });
};

export const createActivityInfo = (
  passengerId?: string,
  activity?: PassengerActivityInfo
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/activityinfo`, activity)
    .then((res) => {
      return res.data;
    });
};

export const createDrinkInfo = (
  passengerId?: string,
  drinkInfo?: PassengerDrinkInformationDTO
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/drinkinfo`, drinkInfo)
    .then((res) => {
      return res.data;
    });
};

export const createFoodInfo = (
  passengerId?: string,
  foodInfo?: PassengerFoodInformation
) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/foodinfo`, foodInfo)
    .then((res) => {
      return res.data;
    });
};

export const uploadPassportFile = (
  uploadProgressFunction: Function,
  passengerId?: string,
  travelInformationId?: string,
  formData?: FormData
) => {
  return axios.post(
    `${API_URL}passengers/file/${travelInformationId}?passengerId=${passengerId}`,
    formData,
    {
      onUploadProgress: (progressEvent) => {
        uploadProgressFunction(progressEvent);
      },
    }
  );
};

export const getPortalInformation = (shipId?: string) => {
  return axios
    .get(`${API_URL}shiptripportalinformation/public/${shipId}`)
    .then((res) => {
      return res.data;
    });
};


export const getPortalFileBlob = (
  portalId?: string,
  fileId?: string,
  shipId?: string
) => {
  return axios
    .get(
      `${API_URL}ShipTripPortalInformation/public/${portalId}/download/${fileId}?shipId=${shipId}`,
      { responseType: "blob", withCredentials: true }
    )
    .then((response) => {
      var filename = "";
      var disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      saveAs(response.data, filename);
      return;
    });
};

export const createPassengerPreferences = (type: number, passengerId?: string, preferences?: TripPassengerPreferenceDTO[]) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/preferences/${type}`, preferences)
    .then((res) => {
      return res.data;
    });
};

export const getPreferences = (shipId?: string) => {
  return axios
    .get(`${API_URL}passengers/ship/${shipId}/preferences`)
    .then((res) => {
      return res.data;
    });
}

export const getTripGeneralQuestions = (shipId?: string) => {
  return axios
    .get(`${API_URL}passengers/ship/${shipId}/generalquestions`)
    .then((res) => {
      return res.data;
    });
}

export const createTripPassengerGeneralQuestion = (type: number, passengerId?: string, questions?: TripPassengerGeneralQuestionDTO[]) => {
  return axios
    .post(`${API_URL}passengers/${passengerId}/generalquestion/${type}`, questions)
    .then((res) => {
      return res.data;
    });
};