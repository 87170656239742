import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import OnboardingStepper from "../components/OnboardingStepper/OnboardingStepper";
import { Formik } from "formik";
import { Star } from "@mui/icons-material";
import { useRecoilValue, useRecoilState } from "recoil";
import { CurrentPassengerState, DrinkPreferencesSelector } from "../recoil/PassengerAtom";
import { useNavigate } from "react-router-dom";
import FormRatingControl from "../components/FormRatingControl/FormRatingControl";
import InfoButton from "../components/InfoButton/InfoButton";
import { createPassengerPreferences, getPassengerDetails } from "../services/passengerService";
import { PreferenceType, TripPreferenceInputType } from "../types/TripPreference";
import { useState } from "react";

type TripPassengerPreferenceDTO = {
  tripPreferenceId: string;
  passengerId: string;
  response: string;
  isAllergy: boolean;
};

type FormValues = {
  [key: string]: {
    value: string | number | boolean;
    isAllergy: boolean;
  };
};

export default function DrinkPreferences() {
  const drinkPreferenceTypes = useRecoilValue(DrinkPreferencesSelector);
  const [currentPassenger, setCurrentPassenger] = useRecoilState(CurrentPassengerState);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  // Filter out inactive preference types and their inactive preferences
  const activeDrinkPreferenceTypes = drinkPreferenceTypes
    .filter(prefType => prefType.isActive)
    .map(prefType => ({
      ...prefType,
      preferences: prefType.preferences.filter(pref => pref.isActive)
    }));

  // Initialize form values for all active preferences within each active preference type
  const initialValues: FormValues = {};
  activeDrinkPreferenceTypes.forEach(prefType => {
    prefType.preferences.forEach(pref => {
      initialValues[pref.id] = {
        value: pref.inputType === TripPreferenceInputType.Rating ? 3 : 
               pref.inputType === TripPreferenceInputType.CheckBox ? false : "",
        isAllergy: false
      };
    });
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      setError(null);

      // Convert form values to TripPassengerPreferenceDTO array
      const preferences: TripPassengerPreferenceDTO[] = Object.entries(values).map(([prefId, formValue]) => ({
        tripPreferenceId: prefId,
        passengerId: currentPassenger.id ?? "",
        response: formValue.value.toString(),
        isAllergy: formValue.isAllergy
      }));

      // Call the service to save preferences
      createPassengerPreferences(PreferenceType.Drink,currentPassenger.id, preferences)
      .then(() => getPassengerDetails(currentPassenger).then((res) => {
        setCurrentPassenger(res.data);
        navigate("/");
      }))
      
    } catch (err) {
      setError("Failed to save drink preferences. Please try again.");
      console.error("Error saving drink preferences:", err);
    }
  };

  return (
    <Container maxWidth="md">
      <OnboardingStepper activeStep={7} />
      <Paper sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Drink Preferences
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Please tell us about your drink preferences to help us prepare your beverages.
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {error && (
                  <Alert severity="error" sx={{ mb: 3 }}>
                    {error}
                  </Alert>
                )}

                <Alert severity="info" variant="outlined" sx={{ mb: 4 }}>
                  <Stack direction="column">
                    <Typography variant="body1">
                      Please rate the following drink preferences. We will do our best to accommodate everyone's
                      preferences, but please understand that some items may not always be available.
                    </Typography>
                    <List>
                      <ListItem>1<Star fontSize="small" /> - Cannot Have</ListItem>
                      <ListItem>2<Star fontSize="small" /> - Dislike</ListItem>
                      <ListItem>3<Star fontSize="small" /> - Neutral</ListItem>
                      <ListItem>4<Star fontSize="small" /> - Like</ListItem>
                      <ListItem>5<Star fontSize="small" /> - Must Have</ListItem>
                    </List>
                  </Stack>
                </Alert>

                {activeDrinkPreferenceTypes.map((preferenceType) => (
                  <Paper 
                    key={preferenceType.id} 
                    variant="outlined" 
                    sx={{ 
                      p: 1.5,
                      mb: 1.5,
                      backgroundColor: 'background.default'
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        mb: 1,
                        pb: 0.5,
                        borderBottom: 1,
                        borderColor: 'divider'
                      }}
                    >
                      {preferenceType.name}
                    </Typography>
                    
                    <Stack 
                      spacing={0.75} 
                      divider={
                        <Divider 
                          sx={{ 
                            opacity: 0.5,
                            my: 0.5
                          }} 
                        />
                      }
                    >
                      {[...preferenceType.preferences]
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .map((preference) => (
                        <Box 
                          key={preference.id} 
                          sx={{ 
                            p: 1,
                            backgroundColor: 'background.paper',
                            borderRadius: 1,
                            '&:hover': {
                              backgroundColor: 'action.hover'
                            }
                          }}
                        >
                          <Grid 
                            container 
                            direction={{ xs: 'column', sm: 'row' }}
                            alignItems={{ xs: 'flex-start', sm: 'center' }}
                            spacing={0.5}
                          >
                            <Grid item xs={12} sm>
                              {preference.inputType === TripPreferenceInputType.Rating && (
                                <FormRatingControl
                                  label={preference.name}
                                  name={`${preference.id}.value`}
                                  value={Number(values[preference.id]?.value ?? 3)}
                                  onChange={(_, value) => setFieldValue(`${preference.id}.value`, value ?? 3)}
                                  description={preference.isDescriptionPublic ? preference.description : undefined}
                                />
                              )}
                              
                              {preference.inputType === TripPreferenceInputType.TextField && (
                                <Grid container alignItems="center" spacing={0.5}>
                                  <Grid item xs>
                                    <TextField
                                      fullWidth
                                      multiline
                                      rows={4}
                                      label={preference.name}
                                      name={`${preference.id}.value`}
                                      value={values[preference.id]?.value ?? ""}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>
                                  {preference.isDescriptionPublic && preference.description && (
                                    <Grid item>
                                      <InfoButton description={preference.description} />
                                    </Grid>
                                  )}
                                </Grid>
                              )}

                              {preference.inputType === TripPreferenceInputType.CheckBox && (
                                <Grid container alignItems="center" spacing={0.5}>
                                  <Grid item>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={`${preference.id}.value`}
                                          checked={Boolean(values[preference.id]?.value)}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      }
                                      label={preference.name}
                                    />
                                  </Grid>
                                  {preference.isDescriptionPublic && preference.description && (
                                    <Grid item>
                                      <InfoButton description={preference.description} />
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                            
                            {preferenceType.type === PreferenceType.Drink && 
                             preference.inputType === TripPreferenceInputType.Rating && (
                              <Grid item xs={12} sm="auto">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={`${preference.id}.isAllergy`}
                                      checked={values[preference.id]?.isAllergy || false}
                                      onChange={handleChange}
                                      size="small"
                                      color="error"
                                    />
                                  }
                                  label="Allergy"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      ))}
                    </Stack>
                  </Paper>
                ))}

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={isSubmitting}
                  >
                    Save Preferences
                  </Button>
                </Box>
              </Stack>
            </form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
}
