import { Grid, Rating, Typography, IconButton, Popover } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useState } from "react";

export type FormRatingControlProps = {
  label: string;
  name: string;
  onChange: (event: React.SyntheticEvent, value: number | null) => void;
  value?: number;
  description?: string;
};

export default function FormRatingControl(props: FormRatingControlProps) {
  const { label, name, onChange, value, description } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `popover-${name}` : undefined;

  return (
    <Grid container alignItems="center">
      <Grid item xs={3} container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="body1">{label}</Typography>
        </Grid>
        {description && (
          <Grid item>
            <IconButton
              size="small"
              onClick={handleClick}
              aria-describedby={id}
            >
              <InfoOutlined fontSize="small" color="info" />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 2 }} variant="body2">
                {description}
              </Typography>
            </Popover>
          </Grid>
        )}
      </Grid>
      <Grid item xs={6}>
        <Rating name={name} onChange={onChange} sx={{ ml: 2 }} value={value} />
      </Grid>
    </Grid>
  );
}
